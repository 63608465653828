import React from "react";
function Navbar() {
  return (
    <div className="navbar">
      <a href="http://cantina.pavalsebastian.com/" target="_blank" rel="noreferrer" >
        Cantina - Web
      </a>
      <a href="http://chat.pavalsebastian.com/" target="_blank" rel="noreferrer">
        Chat - Web
      </a>
      <a href="http://letmecook.pavalsebastian.com/" target="_blank" rel="noreferrer" >
        LetMeCook - Web
      </a>
    </div>
  );
}
export default Navbar;