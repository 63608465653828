import Project from './Project';
import React, { useContext, useEffect } from 'react';
import { HeaderContext } from './HeaderContext';

function HomePage(){

  const { setHeaderTitle } = useContext(HeaderContext);

  useEffect(() => {
    setHeaderTitle("Proiecte personale");
  }, [setHeaderTitle]);

    const projects = [
      {
        name: "Cantina",
        description: "This project aims to manage orders for a \"cantina\", both for customers and cantina staff. The application consists of three components: Client Side(React), Server(ASP.NET), and Control Panel(WPF). The connection between the three components is established through APIs and WebSockets. The database is relational, and I used SQL Server along with Entity Framework.",
        technologies: ["React", "ASP.NET", "API", "WebSocket", "Relational Database", "SQL Server", "Entity Framework", "Fluent Validation"],
        githubLink: "https://github.com/seby1621199/Cantina",
        projectLink: "http://cantina.pavalsebastian.com/"
      },
        {
          name: "Bank Client",
          description: "This project aims to manage accounts in multiple currencies for a user, with conversions made through an API call. The user can select the type of account during registration, and based on their choice, they receive specific benefits.",
          technologies: ["React", "Node.js", "API"],
          githubLink: "https://github.com/seby1621199/ClientBank",
          projectLink: null
        },
        {
          name: "Chat App",
          description: "This project aims to facilitate communication between users through a chat available on Mobile, Desktop, and via a web browser. The project represents an enhanced version of an older one that used a TCP/IP server for client communication.",
          technologies: ["SignalR", ".NET WPF", "MongoDB"],
          githubLink: "https://github.com/seby1621199/Chat",
          projectLink: "http://chat.pavalsebastian.com/"
        },
        {
          name: "Let Me Cook",
          description: "This project aims to extract data from the website bbcgoodfood.com. Based on the chosen ingredient (its name in English), a list of dishes containing that ingredient will be returned, along with a rating assigned to each dish.",
          technologies: ["React", "ASP.NET", "HtmlAgilityPack"],
          githubLink: "https://github.com/seby1621199/LetMeCook",
          projectLink: "http://letmecook.pavalsebastian.com/"
        }
      ];

    return (
        <>
      <div className="projects-list" id="projects">
        {projects.map((project, index) => (
          <Project
            key={index}
            name={project.name}
            description={project.description}
            technologies={project.technologies}
            githubLink={project.githubLink}
            projectLink={project.projectLink}
          />
        ))}
      </div>
      </>
    );
}

export default HomePage;